import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getotp: () => Promise.resolve(),
  resetpass: () => Promise.resolve(),
  verifyotp: () => Promise.resolve(),
  googlelogin: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

          if (accessToken) {
            const user  = accessToken;
  
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          } else {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      };
  
    initialize();
  }, []);

  const login = async (username, password) => {
    const response = await axios.post('/api/signin', {
      username,
      password,
    });
    
    const  user = response.data;
    window.localStorage.setItem('accessToken', JSON.stringify(response.data));
    window.localStorage.setItem('i18nextLng', 'en');

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const googlelogin = async (email) => {
    const response = await axios.post('/api/googlesignin', {
      email,
    });
    const  user = response.data;

    window.localStorage.setItem('accessToken', JSON.stringify(response.data));
    window.localStorage.setItem('i18nextLng', 'en');

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const register = async (username, password, fullname, mobile_no, email_id) => {
    const response = await axios.post('/api/signup', {
      username,
      password,
      fullname,
      mobile_no,
      email_id,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    window.localStorage.setItem('i18nextLng', 'en');
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };
  const getotp = async (data,type) => {
    const response = await axios.post('/api/sendotp', {
      data,
      type,
    });
    const user = response.data;

    dispatch({
      type: 'OTP',
      payload: {
        user,
      },
    });
  };

  const resetpass = async (email, phone, password) => {
    const response = await axios.post('/api/resetpassword', {
      email,
      phone,
      password,
    });
    const user = response.data;

    dispatch({
      type: 'RESET',
      payload: {
        user,
      },
    });
  };
  
  const verifyotp = async (email,phone,otp) => {
    const response = await axios.post('/api/verifyotp', {
      email,
      phone,
      otp,
    });
    const user = response.data;

    dispatch({
      type: 'RESET',
      payload: {
        user,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getotp,
        resetpass,
        verifyotp,
        googlelogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
